import Button from '@/common/components/Button/Button';
import Menu from '@/common/components/Menu/Menu';
import PageHeader from '@/common/components/PageHeader/PageHeader';
import LogoutModal from '@/features/MainMenu/components/LogoutModal';
import { MAIN_MENU_ITEMS } from '@/features/MainMenu/constants';
import { useContainersStore } from '@/store/containers';
import { useCountStore } from '@/store/count';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function HomePage() {
  const { resetLocation: resetLocationCount } = useCountStore();
  const { resetLocation: resetLocationContainers } = useContainersStore();

  const navigate = useNavigate();

  const [logoutModalIsVisible, setLogoutModalIsVisible] = useState(false);

  useEffect(() => {
    return () => {
      resetLocationCount();
      resetLocationContainers();
    };
  }, [
    resetLocationContainers,
    resetLocationCount,
  ]);

  return (
    <>
      <PageHeader title='Main Menu' />
      <Menu
        items={MAIN_MENU_ITEMS}
        onItemClick={(item) => {
          navigate(item.url);
        }}
      />
      <div className='buttons-wrapper'>
        <Button
          color='secondary'
          onClick={() => {
            setLogoutModalIsVisible(true);
          }}
        >
          Logout
        </Button>
      </div>
      {logoutModalIsVisible && (
        <LogoutModal
          onSubmit={() => {
            navigate('/logout');
          }}
          onCancel={() => {
            setLogoutModalIsVisible(false);
          }}
        />
      )}
    </>
  );
}
