import NotificationModal from '@/common/components/Modals/NotificationModal';
import { useNotificationsStore } from '@/store/notifications';
import { useEffect, useState } from 'react';

export default function Notifications() {
  const { notification, reset } = useNotificationsStore();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (notification.type) setIsVisible(true);
  }, [notification.type]);

  useEffect(() => {
    if (!isVisible) reset();
  }, [isVisible, reset]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  if (notification.type) {
    return (
      <NotificationModal
        type={notification.type}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        timeout={notification.timeout}
        onClose={notification.onClose}
      >
        {typeof notification.message === 'string' ? (
          <p className='whitespace-pre-wrap text-center uppercase'>{notification.message}</p>
        ) : (
          notification.message
        )}
      </NotificationModal>
    );
  }

  return null;
}
