import { useElementSize } from '@/common/hooks';
import { Link } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import Icons from '../Icons/Icons';
import Loading from '../Loading/Loading';

export enum BackgroundColor {
  Blue = 'bg-[#367BF5]',
  Gray = 'bg-[#4D4D59]',
}

interface IProps<T> {
  items: readonly T[] | null | undefined;
  labelId?: keyof T;
  hasItemIcon?: boolean;
  isLoading?: boolean;
  onItemClick: (payload: T) => void;
  bgColor?: BackgroundColor;
}

export default function Menu<T>({
  items,
  labelId = 'label' as keyof T,
  hasItemIcon = true,
  isLoading,
  onItemClick,
  bgColor = BackgroundColor.Blue,
}: IProps<T>) {
  const { ref: containerRef, height } = useElementSize();

  return (
    <nav className='flex flex-1 flex-col gap-5 overflow-auto' ref={containerRef}>
      {isLoading ? (
        <Loading />
      ) : items ? (
        <Virtuoso
          style={{ height }}
          data={items}
          itemContent={(index, item) => (
            <div className={index === 0 ? 'pb-2' : index === items.length - 1 ? 'pt-2' : 'py-2'}>
              <Link
                to=''
                className={`link-button ${hasItemIcon ? 'flex items-center justify-between' : ''
                  } ${bgColor}`}
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick(item);
                }}
              >
                {item[labelId] as string}
                {hasItemIcon && <Icons type='arrow-right' fill='white' />}
              </Link>
            </div>
          )}
        />
      ) : null}
    </nav>
  );
}
