import { NOTIFICATION_TYPE } from '@/features/Notifications/constants';
import { NotificationType as NotificationTypeModel } from '@/features/Notifications/models';
import { useEffect, useRef } from 'react';
import ModalWrapper from './ModalWrapper';

interface IProps {
  type: keyof typeof NOTIFICATION_TYPE;
  children: React.ReactNode;
  timeout?: number;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}

export default function NotificationModal({
  type,
  children,
  timeout = 2000,
  isVisible,
  setIsVisible,
  onClose
}: IProps) {
  const colorScheme: { [key in NotificationTypeModel]: string } = {
    error: 'bg-Red-Base text-white z-50',
    success: 'bg-Green-Base text-white z-50',
    warn: 'bg-Yellow-Base text-white z-50',
  };

  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    if (!isVisible) return;
    timeoutRef.current = window.setTimeout(() => {
      setIsVisible(false);

      onClose && onClose();
    }, timeout);

    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isVisible, onClose, setIsVisible, timeout]);

  const cancelTimeout = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  if (!isVisible) return null;

  return (
    <ModalWrapper
      containerId='notifications'
      onClick={() => {
        setIsVisible(false);
        cancelTimeout();
        onClose && onClose();
      }}
      className={colorScheme[NOTIFICATION_TYPE[type]]}
      role='alert'
    >
      {children}
    </ModalWrapper>
  );
}
