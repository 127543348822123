import { RouteObject } from 'react-router-dom';

export const CONTAINERS_PATH = '/containers';

// Staging
export const CUTLISTS_STAGING_PATH = `${CONTAINERS_PATH}/staging`;
export const CUTLISTS_STAGING_CATEGORY_PATH = `${CUTLISTS_STAGING_PATH}/category`;
export const CUTLISTS_STAGING_CONTAINER_PATH = `${CUTLISTS_STAGING_PATH}/container`;
export const CUTLISTS_STAGING_CUTLIST_PATH = `${CUTLISTS_STAGING_PATH}/cutlist`;
export const CUTLISTS_STAGING_LOCATIONS_PATH = `${CUTLISTS_STAGING_CUTLIST_PATH}/locations`;

// Distribution
export const CUTLISTS_DISTRIBUTION_PATH = `${CONTAINERS_PATH}/distribution`;
export const CUTLISTS_DISTRIBUTION_CATEGORY_PATH = `${CUTLISTS_DISTRIBUTION_PATH}/category`;
export const CUTLISTS_DISTRIBUTION_CONTAINER_PATH = `${CUTLISTS_DISTRIBUTION_PATH}/container`;
export const CUTLISTS_DISTRIBUTION_CUTLIST_PATH = `${CUTLISTS_DISTRIBUTION_PATH}/cutlist`;
export const CUTLISTS_DISTRIBUTION_COUNT_PATH = `${CUTLISTS_DISTRIBUTION_CUTLIST_PATH}/count`;
export const CUTLISTS_DISTRIBUTION_LOCATION_PATH = `${CUTLISTS_DISTRIBUTION_COUNT_PATH}/location`;

// Return
export const CUTLISTS_RETURN_PATH = `${CONTAINERS_PATH}/return`;
export const CUTLISTS_RETURN_CATEGORY_PATH = `${CUTLISTS_RETURN_PATH}/category`;
export const CUTLISTS_RETURN_CONTAINER_PATH = `${CUTLISTS_RETURN_PATH}/container`;
export const CUTLISTS_RETURN_CUTLIST_PATH = `${CUTLISTS_RETURN_PATH}/cutlist`;
export const CUTLISTS_RETURN_COUNT_PATH = `${CUTLISTS_RETURN_CUTLIST_PATH}/count`;
export const CUTLISTS_RETURN_LOCATION_PATH = `${CUTLISTS_RETURN_COUNT_PATH}/location`;

export const containersRoutes: RouteObject[] = [
  {
    path: 'containers',
    lazy: () => import('../pages/containers')
  },
  //   CONTAINERS > ADD CONTAINER
  {
    path: 'containers/add-container',
    lazy: () => import('../pages/containers/add-container')
  },
  {
    path: 'containers/add-container/container-id',
    lazy: () => import('../pages/containers/add-container/container-id')
  },
  {
    path: 'containers/add-container/location',
    lazy: () => import('../pages/containers/add-container/location')
  },
  {
    path: 'containers/add-container/new-container',
    lazy: () => import('../pages/containers/add-container/new-container')
  },
  {
    path: 'containers/add-container/new-container/container-type',
    lazy: () => import('../pages/containers/add-container/new-container/container-type')
  },
  {
    path: 'containers/add-container/new-container/location',
    lazy: () => import('../pages/containers/add-container/new-container/location')
  },
  // CONTAINERS > REMOVE CONTAINER
  {
    path: 'containers/remove-container',
    lazy: () => import('../pages/containers/remove-container')
  },
  {
    path: 'containers/remove-container/container-id',
    lazy: () => import('../pages/containers/remove-container/container-id')
  },
  // CONTAINERS > RELOCATE CONTAINER
  {
    path: 'containers/relocate-container',
    lazy: () => import('../pages/containers/relocate-container')
  },
  {
    path: 'containers/relocate-container/container-id',
    lazy: () => import('../pages/containers/relocate-container/container-id')
  },
  {
    path: 'containers/relocate-container/to-location',
    lazy: () => import('../pages/containers/relocate-container/to-location')
  },

  // Staging
  {
    path: CUTLISTS_STAGING_PATH,
    lazy: () => import('../pages/cutlists/staging')
  },
  {
    path: CUTLISTS_STAGING_CATEGORY_PATH,
    lazy: () => import('../pages/cutlists/staging/category')
  },
  {
    path: CUTLISTS_STAGING_CONTAINER_PATH,
    lazy: () => import('../pages/cutlists/staging/container')
  },
  {
    path: CUTLISTS_STAGING_CUTLIST_PATH,
    lazy: () => import('../pages/cutlists/staging/cutlist')
  },
  {
    path: CUTLISTS_STAGING_LOCATIONS_PATH,
    lazy: () => import('../pages/cutlists/staging/location')
  },

  // Distribution
  {
    path: CUTLISTS_DISTRIBUTION_PATH,
    lazy: () => import('../pages/cutlists/distribution')
  },
  {
    path: CUTLISTS_DISTRIBUTION_CATEGORY_PATH,
    lazy: () => import('../pages/cutlists/distribution/category')
  },
  {
    path: CUTLISTS_DISTRIBUTION_CONTAINER_PATH,
    lazy: () => import('../pages/cutlists/distribution/container')
  },
  {
    path: CUTLISTS_DISTRIBUTION_CUTLIST_PATH,
    lazy: () => import('../pages/cutlists/distribution/cutlist')
  },
  {
    path: CUTLISTS_DISTRIBUTION_COUNT_PATH,
    lazy: () => import('../pages/cutlists/distribution/count')
  },
  {
    path: CUTLISTS_DISTRIBUTION_LOCATION_PATH,
    lazy: () => import('../pages/cutlists/distribution/location')
  },


  // Return
  {
    path: CUTLISTS_RETURN_PATH,
    lazy: () => import('../pages/cutlists/return')
  },
  {
    path: CUTLISTS_RETURN_CATEGORY_PATH,
    lazy: () => import('../pages/cutlists/return/category')
  },
  {
    path: CUTLISTS_RETURN_CONTAINER_PATH,
    lazy: () => import('../pages/cutlists/return/container')
  },
  {
    path: CUTLISTS_RETURN_CUTLIST_PATH,
    lazy: () => import('../pages/cutlists/return/cutlist')
  },
  {
    path: CUTLISTS_RETURN_COUNT_PATH,
    lazy: () => import('../pages/cutlists/return/count')
  },
  {
    path: CUTLISTS_RETURN_LOCATION_PATH,
    lazy: () => import('../pages/cutlists/return/location')
  },
];
